<template>
  <div class="tab-content" id="PTtab-13" :key="'PTtab-13'">
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2">베팅내역</h3>
      </div>
      <div class="PTsch">
        <div class="datesearchPTWarp">
          <date-filter :retail="true" @search="loadData(1)"
                       @update="onChangeDateTable"
                       :defaultDay="0"
                       :startDate="mainTableDate.startDate"
                       :endDate="mainTableDate.endDate"
                       :isOldYn="true"
                       :id="'main-date-checkbox1'"
                       @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
        </div>
        <div class="datesearchM">
          <date-filter-mobile :retail="true" @search="loadData(1)"
                              @update="onChangeDateTable"
                              :defaultDay="0"
                              :startDate="mainTableDate.startDate"
                              :endDate="mainTableDate.endDate"
                              :isOldYn="true"
                              :id="'main-date-checkbox1'"
                              @setOldYn="setOldYn" :oldYn="reqData.oldYn"
          />
        </div>
        <div class="searchPT">
          <select v-model="reqData.resultType" class="h36px">
            <option value="" selected>전체</option>
            <option value="win" selected>승</option>
            <option value="lose">패</option>
            <option value="draw">무</option>
            <option value="wait">대기</option>
            <option value="cancel">취소</option>
          </select>
          <select  v-model="reqData.searchType" class="h36px">
            <option value="memId" selected>아이디</option>
            <option value="memNick">닉네임</option>
            <option value="recommenderId">상위유저</option>
          </select>
        </div>
        <div class="searchPT">
          <input type="text" :placeholder="$t('front.member.emptyMemId')" v-model="reqData.searchMemId" class="h36px"/>
          <a @click="loadData(1)" class="sch_Icon">
            <img src="@/assets/img/search.png" alt=""/>
          </a>
        </div>
        <div class="searchPT end">
          <ul>
            <li>
              <button type="button" class="btn-layout btn-red" @click="sortBetAmt('DESC')">
                <img src="@/assets/img/icon_up.svg" />베팅금 많은 순서
              </button>
              <button type="button" class="btn-layout btn-red" @click="sortBetAmt('ASC')">
                <img src="@/assets/img/icon_down.svg" />베팅금 적은 순서
              </button>
            </li>
            <li>
              <button type="button" class="btn-layout btn-mint" @click="sortWinAmt('DESC')">
                <img src="@/assets/img/icon_up.svg" />당첨금 많은 순서
              </button>
              <button type="button" class="btn-layout btn-mint" @click="sortWinAmt('ASC')">
                <img src="@/assets/img/icon_down.svg" />당첨금 적은 순서
              </button>
            </li>
            <li>
              <button type="button" class="btn-layout btn-skybl" @click="sortBetTime('DESC')">
                <img src="@/assets/img/icon_up.svg" />베팅시간 최신순
              </button>
              <button type="button" class="btn-layout btn-skybl" @click="sortBetTime('ASC')">
                <img src="@/assets/img/icon_down.svg" />베팅시간 과거순
              </button>
            </li>
            <li>
              <button type="button" class="btn-layout btn-coB" @click="sortEventTime('DESC')">
                <img src="@/assets/img/icon_up.svg" />경기시간 최신순
              </button>
              <button type="button" class="btn-layout btn-coB" @click="sortEventTime('ASC')">
                <img src="@/assets/img/icon_down.svg" />경기시간 과거순
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="strTablescr">
        <div class="strTablePC">
          <template v-if="Object.keys(betList).length > 0">
            <table class="strTablePT"  v-for="(item) in betList" :key="item[0].betIdx + item[0].betId + item[0].newLineID" >
              <colgroup>
                <col width="5%">
                <col width="5%">
                <col width="10%">
                <col width="5%">
                <col width="10%">
                <col width="10%">
                <col width="5%">
                <col width="10%">
                <col width="10%">
                <col width="5%">
                <col width="5%">
              </colgroup>
              <thead>
                <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>경기시간</th>
                  <th>종목</th>
                  <th>리그명</th>
                  <th>홈팀</th>
                  <th>스코어</th>
                  <th>원정팀</th>
                  <th>베팅위치</th>
                  <th>배당율</th>
                  <th>상태</th>
                </tr>
              </thead>
              <tbody>

              <tr >
                <td>{{ item[0].memId }}</td>
                <td class="nick">{{ item[0].memNick }}</td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ displayDateTime(data.eventDate, true) }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    <em class="orange-bg" :class="data.branchName">{{ SPORT_BRANCH_LIST[data.branchID] }}</em>
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi lg-w id" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ data.leagueNameKO || data.leagueName }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.betIdx + data.betId + data.newLineID" :class="data.status">
                    {{ data.homeTeamKO || data.homeTeam }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ data.score }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ data.awayTeamKO || data.awayTeam }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi betloc" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ data.yourBet }}
                  </span>
                </td>
                <td class="comWrap">
                   <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    <span :class="{ 'ft-01' : data.lineOdds > 0 && data.lineOdds < 1.99, 'ft-02' : data.lineOdds > 2 && data.lineOdds < 4.99, 'ft-03' : data.lineOdds > 5}">{{ data.lineOdds }}</span>
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi c-sts" v-for="data in item" :key="data.newLineID" :class="data.status">
                    <em class="state">{{ STATUS_LIST[data.status] }}</em>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="11" class="no-padd">
                  <div class="commonWrap">
                    <ul class="commonInfo">
                      <li class="c-01 w210px">
                        <em>베팅시간</em><span>{{ replaceDateT(item[0].betDate) }}</span>
                      </li>
                      <li class="c-02">
                        <em>{{ $t('front.bettingStatus.BET') }}ID</em><span>{{ item[0].betId }}</span>
                      </li>
                      <li class="c-03">
                        <template v-if="item[0].betTypeName === '단폴'">
                          <span class="blue-bg" style="color: white;">{{ item[0].betTypeName }}</span>
                        </template>
                        <template v-else>
                          <span class="blue-bg" style="color: white;">{{ item[0].betTypeName }}</span>
                        </template>
                      </li>
                      <li class="c-04">
                        <em>{{ $t('front.stributor.m22') }}</em><span>{{ thousand(item[0].betAmt) }}</span>
                      </li>
                      <li class="c-05">
                        <em>통합 배당률</em><span>{{ calOdds(item) }}</span>
                      </li>
                      <li class="c-06">
                        <em>예상 당첨금</em><span>{{ thousand(exCalOdds(item)) }}</span>
                      </li>
                      <li>
                        <em>{{ $t('front.stributor.m23') }}</em>
                        <span :class="{ 'rdc' : item[0].betWinAmt > 0, 'blc' : item[0].betWinAmt < 0,}">{{ thousand(item[0].betWinAmt) }}</span>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <table class="strTablePT">
              <colgroup>
                <col width="5%">
                <col width="5%">
                <col width="10%">
                <col width="5%">
                <col width="10%">
                <col width="10%">
                <col width="5%">
                <col width="10%">
                <col width="10%">
                <col width="5%">
                <col width="5%">
              </colgroup>
              <thead>
                <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>경기시간</th>
                  <th>종목</th>
                  <th>리그명</th>
                  <th>홈팀</th>
                  <th>스코어</th>
                  <th>원정팀</th>
                  <th>베팅위치</th>
                  <th>배당율</th>
                  <th>상태</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td colspan="11" class="p-14">{{ $t('front.common.notFoundList') }}</td>
              </tr>
              </tbody>
            </table>
          </template>
        </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <template v-if="Object.keys(betList).length > 0">
              <template v-for="(item) in betList" :key="item[0].betIdx + item[0].betId + item[0].newLineID">
                <ul>
                    <li>
                      <em>아이디</em>
                      <div><span>{{ item[0].memId }}</span></div>
                    </li>
                    <li>
                      <em>닉네임</em>
                      <div><span class="nick">{{ item[0].memNick }}</span></div>
                    </li>
                    <li>
                      <em>경기시간</em>
                      <div>
                        <span v-for="data in item" :key="data.newLineID">
                          {{ displayDateTime(data.eventDate, true) }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>종목</em>
                      <div>
                        <span v-for="data in item" :key="data.newLineID">
                          {{ SPORT_BRANCH_LIST[data.branchID] }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>리그명</em>
                      <div>
                        <span v-for="data in item" :key="data.newLineID">
                          {{ data.leagueNameKO || data.leagueName }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>홈팀</em>
                      <div>
                        <span v-for="data in item" :key="data.betIdx + data.betId + data.newLineID">
                          {{ data.homeTeamKO || data.homeTeam }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>스코어</em>
                      <div>
                        <span v-for="data in item" :key="data.newLineID">
                          {{ data.score }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>원정팀</em>
                      <div>
                        <span v-for="data in item" :key="data.newLineID">
                          {{ data.awayTeamKO || data.awayTeam }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>베팅위치</em>
                      <div>
                        <span v-for="data in item" :key="data.newLineID">
                          {{ data.yourBet }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>배당율</em>
                      <div>
                        <span v-for="data in item" :key="data.newLineID">
                          <span>{{ data.lineOdds }}</span>
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>상태</em>
                      <div class="fx-center-5">
                        <span v-for="data in item" :key="data.newLineID" :class="data.status">
                          <em class="state">{{ STATUS_LIST[data.status] }}</em>
                        </span>
                      </div>
                    </li>
                    <li>
                      <em>베팅시간</em>
                      <div><span>{{ replaceDateT(item[0].betDate) }}</span></div>
                    </li>
                    <li>
                      <em>{{ $t('front.bettingStatus.BET') }}ID</em>
                      <div><span>{{ item[0].betId }}</span></div>
                    </li>
                    <li>
                      <em></em>
                      <div>
                        <template v-if="item[0].betTypeName === '단폴'">
                          <span class="blue-bg" style="color: white;">{{ item[0].betTypeName }}</span>
                        </template>
                        <template v-else>
                          <span class="blue-bg" style="color: white;">{{ item[0].betTypeName }}</span>
                        </template>
                      </div>
                    </li>
                    <li>
                      <em>{{ $t('front.stributor.m22') }}</em>
                      <div><span>{{ thousand(item[0].betAmt) }}</span></div>
                    </li>
                    <li>
                      <em>통합 배당률</em>
                      <div><span>{{ calOdds(item) }}</span></div>
                    </li>
                    <li>
                      <em>예상 당첨금</em>
                      <div><span>{{ thousand(exCalOdds(item)) }}</span></div>
                    </li>
                    <li>
                      <em>{{ $t('front.stributor.m23') }}</em>
                      <div><span :class="{ 'rdc' : item[0].betWinAmt > 0, 'blc' : item[0].betWinAmt < 0,}">{{ thousand(item[0].betWinAmt) }}</span></div>
                    </li>
                </ul>
              </template>
            </template>
            <template v-else>
              <ul>
                <li class="nodata">내역 없음</li>
              </ul>
            </template>

          </div>
        </div>
      </div>
      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="loadData"
      />
    </div>
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Pagination from '@/components/ui/Pagination.vue'
import { displayDateTime, getDateStr, replaceDateT, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { getBottomSportBettingList, partnerLevels } from '@/api/retail'
import { SPORT_BRANCH_LIST, STATUS_LIST } from '@/libs/constants'
export default {
  name: 'PartnerBettingSport',
  components: { DateFilter, DateFilterMobile, Pagination },
  computed: {
    SPORT_BRANCH_LIST () {
      return SPORT_BRANCH_LIST
    },
    STATUS_LIST () {
      return STATUS_LIST
    },
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        page: 1,
        count_per_list: 100,
        memId: '',
        branchID: null,
        orderColumn: null,
        orderType: null,
        startDate: '',
        endDate: '',
        offset: -1,
        oldYn: 'N'
      },
      betList: {},
      branchIDList: [],
      order: ''
    }
  },
  async created () {
    this.emitter.emit('Loading', true)

    this.branchIDList.push({
      value: null,
      text: '전체'
    })
    for (const key in SPORT_BRANCH_LIST) {
      this.branchIDList.push({
        value: key,
        text: SPORT_BRANCH_LIST[key]
      })
    }

    this.getPartnerLevels()
    this.reqData.startDate = getDateStr(new Date(this.mainTableDate.startDate))
    this.reqData.endDate = getDateStr(new Date(this.mainTableDate.endDate))
    await this.loadData()
    this.emitter.emit('Loading', false)
  },
  methods: {
    replaceDateT,
    displayDateTime,
    typeName (type) {
      const TYPE_TEXT = {
        win: '승',
        lose: '패',
        draw: '무',
        cancel: '취소',
        wait: '대기'
      }
      return TYPE_TEXT[type]
    },
    thousand,
    setOldYn (data) {
      console.log(data)
      this.reqData.oldYn = data
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangeDateTable (value) {
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    async loadData (pageNum) {
      this.emitter.emit('Loading', true)
      if (pageNum) {
        this.reqData.page = pageNum
      } else {
        this.reqData.page = 1
      }

      const data = this.reqData
      if (this.order !== '') {
        const tempOrder = this.order.split(' ')
        const orderColumn = tempOrder[0]
        const orderType = tempOrder[1]

        data.orderColumn = orderColumn
        data.orderType = orderType
      } else {
        data.orderColumn = ''
        data.orderType = ''
      }

      console.log('list params : ', data)
      getBottomSportBettingList(data).then(res => {
        console.log(res.data)
        this.betList = {}
        if (res.data.resultCode === '0') {
          const list = res.data.data.list

          console.log(list)

          const temp = {}
          list.forEach(item => {
            const betIdx = item.betIdx
            const betId = item.betId
            const key = betIdx + betId
            if (!temp[key]) {
              temp[key] = []
            }
            temp[key].push(item)
          })

          console.log(temp)

          this.betList = temp
        }
        this.emitter.emit('Loading', false)
      })
    },
    sortBetAmt (order) {
      this.order = `betAmt ${order}`
      this.loadData(1)
    },
    sortWinAmt (order) {
      this.order = `betWinAmt ${order}`
      this.loadData(1)
    },
    sortBetTime (order) {
      this.order = `betTime ${order}`
      this.loadData(1)
    },
    sortEventTime (order) {
      this.order = `eventTime ${order}`
      this.loadData(1)
    },
    shouldApplyClass (item) {
      return item.length > 1
    },
    calOdds (item) {
      const totalodd = item.reduce((accumulator, data) => {
        return accumulator * data.lineOdds
      }, 1)

      const roundedTotalOdd = Math.floor(totalodd * 100) / 100

      return roundedTotalOdd.toFixed(2)
    },
    exCalOdds (item) {
      const odds = this.calOdds(item)
      const extotalodd = item.reduce((accumulator, data) => {
        return odds * data.betAmt
      }, 1)
      return extotalodd.toFixed(0)
    }
  }
}
</script>
<style scoped>
.p-14 {padding: 14px 0 !important;}
.fx-center-5 {display: flex;align-items: center;gap: 5px;}
.PTsch .searchPT h5{line-height: 30px; font-size: 14px;}
.searchPT.end {margin-left: auto;margin-top: 21px;}
.searchPT.end ul {
  display: flex; gap: 10px;
}
.searchPT.end li {
  display: flex; gap: 5px; flex-direction: column;
}
.strTablePC .strTablePT {margin-bottom: 30px;}
.strTablePC .strTablePT th {background: #fbfbfb;border-top: 1px solid #d6d6d6;border-bottom: 1px solid #d6d6d6;}
.strTablePC .strTablePT td {background: none !important;padding: 0;}

.strTableM .state {width: 50px;height: 20px;}
.strTableM span {display: flex;background: none;}
.strTableM .blue-bg {display: inline-block;}

.btn-coB {
  background: #edb838;
}
.btn-skybl {
  background: #c63d40;
}
.btn-mint {
  background: #42b182;
}
.btn-red {
  background: #5068d4;
}
.btn-layout {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 8px;
  color: #fff !important;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-radius: 5px;
  border: 0;
}
.btn-layout img {height: 11px;}

tr.point-tr {border-bottom: 3px solid #bacfce; border-top: 3px solid #bacfce;}
tr.point-tr td {background: #f7f7f7;}
.w210px{
  width: 210px;
}
.w270px{
  width: 270px;
}
.combi {display: flex;align-items: center;justify-content: center;height: 35px;border-top: 1px solid #e1e0e0;}
.combi:first-child {border-top: 0;}
.combi.on {background: #cdd1f1;}
.id {overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.orange-bg{
  padding: 5px 5px 5px 25px;
  text-align: center;
  box-sizing: border-box;
  color:#eb7a3f;
}
.blue-bg{
  padding: 3px 15px;
  text-align: center;
  box-sizing: border-box;
  color:#252930 !important;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #808080;
  background-image: linear-gradient(to bottom, #fff 19%, #f6f3e9);
}
.purple-bg{
  padding: 5px 8px;
  text-align: center;
  box-sizing: border-box;
  color:#fff !important;
  background: #ad0e6c;
  border-radius: 3px;;
}
.orange-bg.combi {
  max-width: 90%;
  width: auto;
  margin: 0 auto;
}
.betloc{
  color: #0043bd;
}
.fc-id {
  color: #eb7a3f !important;
}
.ft-01 {
  color: #31ada7;
}
.ft-02 {
  color: #7c46a5;
}
.ft-03 {
  color: #7c46a5;
}
.c-sts {
  color: rgb(255, 145, 0);
}
.c-07 span {color:#c50101; font-size:1.1em; font-weight:600;}
.lg-w {
  padding: 0 8px;
  justify-content: flex-start;
  overflow-x: scroll;
  box-sizing: border-box;
}
.lg-w::-webkit-scrollbar{
  height: 0px;
  width: 5px;
}
.lg-w::-webkit-scrollbar-thumb{
  background: #82a6a4;
}
.lg-w::-webkit-scrollbar-track{
  background: none;
}
.Lost {background-color: #f7d4d4;}
.Won {background-color: #d7f7d9;}
.state {width: 57px;height: 20px;border-radius: 2px;box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);border: solid 1px #808080;background-image: linear-gradient(to bottom, #fff 19%, #f6f3e9);color: #252930;font-size: 11px;display: flex;align-items: end;justify-content: center;box-sizing: border-box;}
.Lost .state {background: #3f64f8;color :#fff;}
.Won .state {background: #e50000;color :#fff;}

.comWrap{position: relative;}
.commonWrap{display: flex; justify-content: space-between;height: 100%;box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);background-color: #eee;}
.commonInfo {display: flex;justify-content: center; gap: 40px; width: 100%;height: 40px;color: #898989;}
.commonInfo li {position: relative; display: flex;align-items: center;gap: 5px;}
.commonInfo li em {font-weight: 900;}
.commonInfo li span {font-weight: 400;}
.commonInfo li::after {content: ''; position: absolute; right: -10px; top: 4px; width: 1px; height: 15px; display: none; background: #7e7e7e;}
.commonInfo li:last-child::after {display: none;}
.no-padd {padding: 0 !important;}

.Soccer {background: url(~@/assets/img/icon_soccer.png) center left no-repeat;}
.Volleyball {background: url(~@/assets/img/icon_volleyball.png) center left no-repeat;}
.Basketball {background: url(~@/assets/img/icon_basketball.png) center left no-repeat;}
.Baseball {background: url(~@/assets/img/icon_baseball.png) center left no-repeat;}
.ussoccer {background: url(~@/assets/img/icon_ussoccer.png) center left no-repeat;}
.haki {background: url(~@/assets/img/icon_haki.png) center left no-repeat;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped src="@/styles/striNew.css"></style>
